<template>
    <b-modal id="modalEmployee" :title="$t(`${!editEmployee ? 'Datos Empleados' : 'Editar Empleado'}`)" modal-class="custom-dialog dialog-600" :ok-title="$t(`${!editEmployee ? 'Crear' : 'Guardar cambios'}`)" ok-variant="warning" 
         ok-only no-close-on-esc no-close-on-backdrop centered @close="close" @ok="ok" :ok-disabled="loading">
      <b-form-checkbox
          v-if="!!editEmployee && !loading"
          switch
          class=" mt-1 mb-1 checkbox-switc/h switch-pudo"
          v-model="form.status">
          {{ !!form.status ? 'Activo' : 'Inactivo' }}
      </b-form-checkbox>
      <form-render v-show="!loading" :form.sync="form" :fields="fields" @send="onAccept" containerButtonsClass="col-sm-12" ref="formRenderEmployee">
      </form-render>
      <div v-show="loading">
        <b-skeleton width="30%"/>
        <b-skeleton type="input"/>
        <b-skeleton width="30%" class="mt-1"/>
        <b-skeleton type="input"/>
      </div>
    </b-modal>
  </template>
  
<script>
export default {
  name:'modalEmployee',
  props: ['employee'],
  data () {
    return {
      form: {},
      fields: [],
      loading: true,
      editEmployee: false,
      keyFormRender: 0
    }
  },
  watch: {
    employee () {
      this.form = {
        ...this.employee,
        status: !!this.employee.status
      }
      // Validamos si es una creación de empleado o edición de empleado
      this.editEmployee = !!this.employee.id
      this.loading = false
      this.keyFormRender++
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      const session_user = this.$session.get('cas_user')
      this.fields = [ 
        {fieldType: 'FieldInput', name: 'first_name', label: 'Nombre', containerClass: 'col-sm-12 container-info'},
        {fieldType: 'FieldInput', name: 'last_name', label: 'Apellido', containerClass: 'col-sm-12 container-info'},
        {fieldType: 'FieldInput', name: 'phone', label: 'Teléfono de contacto', containerClass: 'col-sm-12 container-info', type: 'number', prepend : {text: `+${session_user.country.phone_code}`, color: '#BBBBBB'}, clearable: true },
        {fieldType: 'FieldInput', name: 'email', type: 'email', label: 'Correo de contacto', containerClass: 'col-sm-12 container-info', validation: 'required|email', iconLeft: {icon: 'MailIcon', size: '20', stroke: 1.5, color: '#BBBBBB'}, clearable: true}
      ]
      this.loading = false
    },

    ok (e) {
      e.preventDefault()
      this.$refs.formRenderEmployee.checkForm()
    },
    close () {
      this.$emit('update:employee', {})
    },
    onAccept (data) {
      this.loading = true
      const form = {
        first_name: data.first_name ? data.first_name : '',
        last_name: data.last_name ? data.last_name : '',
        phone: data.phone ? data.phone : '',
        email: data.email ? data.email : ''
      }
      if (!!this.editEmployee) form.status = this.form.status
      this.$emit('send', { form, id: this.editEmployee ? this.form.id : null, original: data})
    }
  }

}
</script>
<style>
.switch-pudo 
.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:active ~ .custom-control-label::before {
    border-color: #1E8E3E;
    background-color: #1E8E3E;
}
</style>