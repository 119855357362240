<template>
    <div class="container-employee">
        <div class="col-md-12">
            <div class="row justify-content-end flex-mobile-bottom">
                <b-button class="push-right separation-buttons custom-button" variant="outline-primary" :disabled="!loading.first" @click="openModalEmployee()">
                    <feather-icon icon="PlusIcon"/>
                    {{$t('Nuevo empleado')}}
                </b-button>
            </div>
        </div>
        <table-render v-if="loading.first"
        :key="keyTableRender"
        :schema="schema" 
        :rows.sync="rows"
        :stickyHeader="`calc(100vh - 2.5rem)`"
        :actions="actions"
        id="pudo"
        :tableClass="'with-scroll'"
        :loading="!loading.employee"
        class="mt-1"
        >
          <template #nameFormatter="scope">
          <span>{{ scope.rowdata.first_name }} {{ scope.rowdata.last_name }}</span>
          </template>
          <template #status="scope">
          <span> {{ !!scope.rowdata.status ? 'Activo' : 'Inactivo' }}</span>
          </template>
        </table-render>
        <b-skeleton height="40px" width="100%" class="mt-2 mb-2" v-show="!loading.first"/>
        <modal-employee ref="modalEmployee" @send="saveEmployee" :employee="currentEmployee"></modal-employee>
    </div>
</template>
<script>
import BaseServices from '@/store/services/index'
import ModalEmployee from '../modal-details/ModalEmployee.vue'
export default {
  name: 'pudo-edit',
  props: ['pudo'],
  components: {ModalEmployee},
  data () {
    return {
      keyTableRender: 0,
      currentEmployee : {},
      schema: [],
      rows: [],
      actions: [],
      loading: {
        first: false,
        employee: false
      },
      baseService: new BaseServices(this)
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.schema = [
        {label: 'Nombre', sortable: true, useSlot: true, key: 'nameFormatter'},
        {label: 'Número de contacto', sortable: true, key: 'phone'},
        {label: 'Email', sortable: true, key: 'email' },
        {label: 'Estado', sortable: true, useSlot: true, key: 'status'},
        {label: 'Acciones', key: 'actions', class: ['text-center']}
      ]
      this.actions = [
        {action: id => this.openEditModal(id), icon: 'FileTextIcon', color: 'primary', text: 'Editar empleado' }
      ]
      this.rows = this.pudo.users
      this.loading.first = true
      this.loading.employee = true
    },
    openModalEmployee () {
      this.currentEmployee = {}
      this.$bvModal.show('modalEmployee')
    },
    saveEmployee (data) {
      data.form = {
        ...data.form,
        pudo_id: this.pudo.id,
        company_id: this.pudo.owner.id
      }
      this.$refs.modalEmployee.loading = true
      if (!!data?.id) this.updateEmployee(data)
      else this.createEmployee(data)
    },
    createEmployee (data) {
      const queryParams = { ...data.form }
      return this.baseService.callService('postCreateEmployeePudo', queryParams)
        .then(resp => {
          this.rows.unshift(resp.data)
          this.$emit('refreshUsers', this.rows)
          this.$bvModal.hide('modalEmployee')
          this.$success(this.$t('msg-alerta-nuevo-empleado'))
        })
        .catch(err => {
          this.$refs.modalEmployee.loading = false
          this.$alert(this.$t('msg-problema-guardar', {code: err}))
        })

    },
    openEditModal (id) {
      this.currentEmployee = {
        ...this.rows.find(el => el.id === id)
      }
      this.$bvModal.show('modalEmployee')
    },
    updateEmployee (data) {
      const queryParams = { ...data.form, id: data.id }
      return this.baseService.callService('putCreateEmployeePudo', queryParams)
        .then(resp => {
          const index = this.rows.findIndex(el => el.id === data.id)
          this.rows[index] = resp.data
          this.$emit('refreshUsers', this.rows)
          this.keyTableRender++
          this.$bvModal.hide('modalEmployee')
          this.$success(this.$t('msg-alerta-editar-empleado'))
        })
        .catch(err => {
          this.$refs.modalEmployee.loading = false
          this.$alert(this.$t('msg-problema-guardar', {code: err}))
        })
    }

  }
}
</script>
<style lang='scss'>
.custom-button:hover {
    background: #1B4284 !important;
    color: #FFFFFF !important;
}
.custom-button:not(:disabled):not(.disabled):focus {
    border: 1px solid #044389;
    background-color: transparent;
}
.container-employee {
  margin: 0 2rem;
}
</style>