<template>
    <div class="container-schedules" >
        <filter-swapper
          :trigger="selectedRows.length === 0"
          :buttons="buttons"
          :controlHeightButtons="controlHeight"
          id="schedules_swapper"
        />
        <field-schedule
          :selectedRows.sync="selectedRows"
          :showCheckboxes="true"
          :placeholder="''"
          :value="formSchedules"
          :hoursOptions="hours"
          :outSideAddButton="true"
          :hideDeleteBtn="true"
          :selectAllByDefault="true"
          rangeLabelStart="Apertura"
          rangeLabelClose="Cierre"
          :customizeFieldHours="true"
          :disabledSchedulesForm="disabledSchedulesForm"
        />
        <div class="d-flex justify-content-end mb-2 animate-btn-saved" >
          <b-button v-if="showBtnSaved" variant="warning" class="push-right" :disabled="btn_saved" @click="editSchedules()">
            <i v-if="loading.saved" :class="['fa', 'fa-spinner', 'fa-spin']"/>
            {{$t('Guardar')}}
          </b-button>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'

export default {
  name:'dataGeneral',
  props: ['schedules', 'disabledSchedulesForm'],
  data () {
    return {
      buttons: [],
      selectedRows: [],
      controlHeight: { class: 'row mb-2 spacing-label-field'},
      hours: {
        open: [],
        close: []
      },
      formSchedules: [
        {
          days: [],
          close: null,
          open: null
        }
      ],
      daysOptions: {
        1: [
          { id: '*', text: 'Todos los días', days: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'], disabled: false},
          { id: 'LU', text: 'Lunes', disabled: false},
          { id: 'MA', text: 'Martes', disabled: false },
          { id: 'MI', text: 'Miércoles', disabled: false },
          { id: 'JU', text: 'Jueves', disabled: false },
          { id: 'VI', text: 'Viernes', disabled: false },
          { id: 'SÁ', text: 'Sábado', disabled: false },
          { id: 'DO', text: 'Domingo', disabled: false }
        ]
      },
      showBtnSaved: true,
      btn_saved: true,
      typeEvent: {
        delete: false,
        edit: false
      },
      forceDisabledBtn: false,
      loading: {
        saved: false
      }
    }
  },
  watch: {
    formSchedules: {
      handler (val, oldVal) {
        if (!!this.forceDisabledBtn) {
          this.btn_saved = true
          this.forceDisabledBtn = false
        } else {
          this.fieldsIncomplete = val.filter(item => !item.close || !item.open || item.days.length === 0)
          this.btn_saved = this.fieldsIncomplete.length > 0 || oldVal[0].days.length === 0
          if (this.disabledSchedulesForm) this.disabledForm()
        }
      },
      deep: true
    },
    selectedRows () {
      this.showBtnSaved = this.selectedRows.length === 0 
      this.selectedRows.length > 0 ? this.$animateCSS('.animate-btn-saved', 'fadeOut', 'faster') : this.$animateCSS('.animate-btn-saved', 'fadeIn', 'faster')
    }
  },
  mounted () { 
    this.setSchedules()
    if (this.disabledSchedulesForm) this.disabledForm()
    this.hours.open = this.m_generateHOurs('7:00', '12:00', 30)
    this.hours.close = this.m_generateHOurs('16:00', '24:00', 30)
  },
  methods: {
    disabledForm () {
      this.btn_saved = true
    },
    setSchedules () {
      this.buttons = [
        { name: 'delete', text: 'Eliminar', color: 'danger',  icon: 'TrashIcon', action: this.confirmDeleteSchedules}
      ]
      this.formatterSchedulesResponse(this.schedules)
    },
    editSchedules () {
      this.typeEvent.edit = true
      this.typeEvent.delete = false
      this.formatterPayloadSchedules(this.formSchedules)
    },
    deleteSchedules () {
      const filterDeleteSchedules = this.formSchedules.filter(item => {
        const idsDelete = this.selectedRows.map(el => el.id)
        return !idsDelete.includes(item.id)
      })
      this.typeEvent.delete = true
      this.typeEvent.edit = false
      this.formatterPayloadSchedules(filterDeleteSchedules)
    },
    confirmDeleteSchedules () {
      if (this.fieldsIncomplete.length === 0) {
        this.$yesno(this.$t('msg-pregunta-eliminar-schedules'), () => this.deleteSchedules())
      } else {
        this.$bvToast.toast(this.$t('msg-valid-complete-form'), {
          title: 'Atención',
          autoHideDelay: 2000,
          variant: 'warning'
        })
      }
    },
    formatterPayloadSchedules (form) {
      const payload = form.map(item => {
        const arrayDays = item.days.map(el => {
          return el.text !== 'Todos los días' ? el.text : el.days
        })
        return {
          close: item.close?.text || '',
          days: Array.isArray(arrayDays[0]) ? arrayDays[0] : arrayDays,
          open: item.open?.text || ''
        }
      })
      this.putSchedules(payload)
    },
    putSchedules (payload) {
      // Alerta para avisar al usuario que se esta realizando la edición/eliminación/creación
      Swal.fire({
        title: 'En progreso',
        html: 'Espere por favor...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      })
      this.loading.saved = true
      this.btn_saved = true
      this.forceDisabledBtn = true
      const params = {
        id: this.$route.params.id
      }
      const queryParams = payload
      this.$store.dispatch('fetchService', { name: 'putSchedules', params, queryParams, onSuccess: (resp) => this.onSuccessSchedules(resp.data), onError: (err) => this.onErrorSchedules(err), hideAlert: true})
    },
    onSuccessSchedules (data) {
      this.loading.saved = false
      this.selectedRows = []
      this.formatterSchedulesResponse(data)
      this.$emit('changeSchedules', data)
      const message = !!this.typeEvent.delete ? 'msg-exito-eliminar-schedules' : 'msg-exito-actualizar-schedules'
      this.$success(this.$t(message))
      this.forceDisabledBtn = true
    },
    onErrorSchedules (err) {
      this.loading.saved = false
      this.selectedRows = []
      this.formatterSchedulesResponse(this.schedules)
      this.forceDisabledBtn = true
      this.$alert(this.$t(err))
    },
    formatterSchedulesResponse (schedules) {
      const formatterArrayDays = schedules.map((item, index) => {
        const formatterDay = item.days.map(day => {
          return {
            id: day.substr(0, 2).toUpperCase(),
            text: day
          }
        })
        return {
          ...item,
          id: index,
          close: {id: item.close, text: item.close},
          days: formatterDay.length === 7 ? [this.daysOptions[1][0]] : formatterDay,
          open: {id: item.open, text: item.open}
        }
      })
      this.formSchedules = formatterArrayDays
    }
  }
}
</script>

<style lang="scss">
.container-schedules{
    margin: 2rem;
}
.col-md-personalize {
    padding-right: 1rem;
    padding-left: 1rem;
    flex: 0 0 32%;
    max-width: 32%;
}
#days .vs__dropdown-option--disabled:hover {
  cursor: not-allowed;
}
#days .vs__selected {
  background-color: #044389 !important;
}
</style>