<template>
    <div class="container-history">
        <table-render v-if="loading.first"
        :key="keyTableRender"
        :schema="schema" 
        :rows.sync="rows"
        :stickyHeader="`calc(100vh - 2.5rem)`"
        :actions="actions"
        id="history"
        :tableClass="'with-scroll'"
        class="mt-1"
        >
          <template #status="scope">
          <span> {{ !!scope.rowdata.activated ? 'Habilitado' : 'Desabilitado' }}</span>
          </template>
        </table-render>
        <div class="row justify-content-end mt-1" v-if="loading.first">
          <p>Punto de retiro {{ pudo.id }} creado {{ pudo.created_at }}</p>
        </div>
        <b-skeleton height="40px" width="100%" class="mt-2 mb-2" v-show="!loading.first"/>
    </div>
</template>

<script>
export default {
  name: 'pudo-edit',
  props: ['pudo'],
  data () {
    return {
      keyTableRender: 0,
      schema: [],
      rows: [],
      actions: [],
      loading: {
        first: false
      }
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: { 
    setInitialData () {
      this.setSchema()
      this.rows = this.pudo.status_history
      this.loading.first = true
    },
    setSchema () {
      this.schema = [
        {label: 'Estado', sortable: true, useSlot: true, key: 'status'},
        {label: 'Usuario', sortable: true, key: 'email'},
        {label: 'Observaciones', sortable: true, key: 'observations' },
        {label: 'Última modificación', sortable: true, key: 'updated_at' }
      ]
    }

  }
}
</script>

<style>
.container-history {
    margin: 5rem 2rem 0rem 2rem;
}
</style>