<template>
  <div :class="!!showMap ? 'position-relative' : ''">
    <div class="container-info-general">
      <div>
          <b-form-checkbox
          switch
          class="ml-2 checkbox-switch switch-pudo"
          v-model="formPudo.activated"
          :disabled="disabledAllFields">
          <span class="personalize-status">{{ !!formPudo.activated ? 'Habilitado' : 'Deshabilitado' }}</span>
          </b-form-checkbox>
          <div class="row ml-1 mt-1">
              <div class="col-md-10">
                <form-render
                  :disableAll="disabledAllFields"
                  ref="formRenderPudo"
                  :form.sync="formPudo"
                  :fields="fieldsPudo"
                  :key="keyFormRenderPudo"
                  @send="handleSubmitPudo"/>
              </div>
          </div>
      </div>
      <div>
          <div class="row ml-1 mt-1">
              <div class="col-md-12">
                <form-render
                  :disableAll="disabledAllFields"
                  ref="formRenderPudoCountry"
                  :form.sync="formPudo"
                  :fields="fieldsPudoCountry"
                  :key="keyFormRenderPudoCountry"/>
              </div>
              <div class="col-md-12 mt-2" v-if="!!showMap">
                <google-map id="map" :infoMap="showMap" :update="changeDirection" :size="{width: '100%', height: '400px'}"/>
              </div>
          </div>
      </div>
    </div>
    <div v-if="!disabledAllFields" class="personalize-btn-saved-changes" :class="!!showMap ? 'flex-start' : 'flex-end'">
      <b-button @click="validateForm" :disabled="!!btn_saved" variant="warning">
        <span>Guardar cambios</span>
      </b-button>
    </div> 
  </div>
</template>

<script>
import BaseServices from '@/store/services/index'
import { mapGetters } from 'vuex'
export default {
  name:'dataGeneral',
  props: ['pudo', 'mySession'],
  data () {
    return {
      formPudo : {},
      fieldsPudoCountry: [],
      fieldsPudo: [],      
      keyFormRenderPudo: 0,
      keyFormRenderPudoCountry: 0,
      showMap: null,
      formattedCountry: [],
      nextLevelData: {},
      baseService: new BaseServices(this),
      btn_saved: false,
      countriesNames: [
        {id: 1, name: 'Chile'},
        {id: 3, name: 'Colombia'},
        {id: 2, name: 'Mexico'},
        {id: 4, name: 'Peru'}
      ],
      internalLevels: {
        cl: {
          required: [3],
          display: [3]
        },
        co: {
          required: [1, 2],
          display: [1, 2]
        },
        pe: {
          required: [1, 2, 3],
          display: [2, 3]
        },
        ar: {
          required: [1, 2, 3],
          display: [1, 2, 3]
        },
        mx: {
          required: [1, 2, 3, 4],
          display: [3, 4]
        },
        ec: {
          required: [1, 2, 3],
          display: [1, 2, 3]
        }
      },
      disabledAllFields: false,
      TYPE: Object.freeze({
        ORGANIZATION: 'Organization',
        SELLER: 'Seller',
        COURIER: 'Courier'
      }),
      complete_levels_in_mx: false
    }
  },
  watch: {
    pudo () {
      this.setPudo()
    },
    countries () {
      this.setCountries()
    },
    organizations () {
      this.setOrganizations()
    },
    shippersByOrganization () {
      this.setSelectOptions('shipper', { options: this.shippersByOrganization }, this.fieldsPudo)
    },
    carriers () {
      this.setSelectOptions('courier', { options: this.carriers }, this.fieldsPudo)
    },
    /* formPudo: {
      handler (val, oldVal) {
        this.btn_saved = Object.entries(oldVal).length === 0
      },
      deep: true
    }, */
    mySession () {
      if (this.mySession.id) this.setInitialData()
    }
  },
  computed: {
    ...mapGetters({
      levels: 'getLevels',
      shippersByOrganization: 'getShippersByOrganizationSimplifiedQueryParams',
      organizations: 'getOrganizationsGlobal',
      countries: 'getCountries',
      carriers: 'getCarriersPudos'
    })
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.fieldsPudo = [
        {fieldType: 'FieldInput', name: 'name', label: 'Nombre', containerClass: 'col-md-4 container-info mb-5', placeholder: 'Ingrese nombre', validation: 'required', disabled: false},
        {fieldType: 'FieldInput', name: 'code', label: 'Código Punto De Retiro', containerClass: 'col-md-4 container-info mb-5 personalize-form-enabled', placeholder: 'Ingrese Código Punto de retiro', validation: 'required', disabled: true},  
        {fieldType: 'FieldInput', name: 'deadline_days', type:'number', label: 'Plazo para retiro', containerClass: 'col-md-12 container-info', placeholder: 'Ingrese días hábiles', max: 3, validation: 'required', disabled: false},
        {fieldType: 'FieldTags', name: 'emails', label: 'Empleado', containerClass: 'col-md-12 container-info mt-2', disabled: this.disabledAllFields, placeholder: 'Agregar correo empleado', validation: 'required', newTagsDesign: true,
          icon: {type: 'MailIcon'},
          tagsOption: {
            validateEmail: true,
            duplicateTagText: 'Correo duplicado no se puede volver a agregar',
            customTag: {background: '#F5D098', color: '#1D192B', colorBtnClose: '#1D192B'}
          }
        }
      ]
      this.fieldsPudoCountry = [
        {fieldType: 'FieldSelect', name: 'country', label: 'País', options: this.formattedCountry, containerClass: 'col-md-4 container-info', change: this.changeCountry, addFlags: true, validation: 'required', disabled: true},
        {fieldType: 'FieldInput', name: 'state_mx', disabled: true, label: 'Estado', containerClass: 'hide'},
        {fieldType: 'FieldInput', name: 'municipality_mx', disabled: true, label: 'Municipio', containerClass: 'hide'},
        {fieldType: 'FieldAutocomplete', name: 'address', label: 'Dirección', dependency: 'country', containerClass: 'col-md-12 container-info', validation: 'required', placeholder: 'Ingrese dirección',
          searchOnType: { fx: this.searchGoogleAddress, nChar: 3, debounce: 300, allowSearchAsValue: true, persistSearch: true }, clearable: true, change: this.changePositionMarkerMap, disabled: false, iconLeft: {icon: 'MapPinIcon', size: '20', stroke: 1.5, color: '#f18805', customizeTopInMain: '4.3rem'}},
        {fieldType: 'FieldInput', name: 'information',  dependency: 'address', label: 'Información adicional', containerClass: 'col-sm-12 container-info', placeholder: 'Ingrese información adicional'}
      ]
      this.sessionUser = this.$session.get('cas_user')
      this.isAdmin = ['admin', 'superadmin'].includes(this.sessionUser.role) 
      this.isMarketplace = ['marketplace'].includes(this.sessionUser.role)
      this.isSeller = ['seller', 'ecommerce'].includes(this.sessionUser.role)
      this.setCountries()
      this.setPudo()
      this.setOrganizations()
    },
    setCountries () {
      if (this.countries && !!this.countries.length) {
        this.formattedCountry = this.countries.filter(item => {
          return item.code.toLowerCase() !== 'ar' && item.code.toLowerCase() !== 'ec'
        }) 
        this.setSelectOptions('country', { options: this.formattedCountry }, this.fieldsPudoCountry)
      }
    },
    setOrganizations () {
      if (this.organizations && !!this.organizations.length) {
        this.formattedOrganization = this.organizations.map(el => {
          return el.organization_type.id === 1 ? {...el, text: `${el.name} (M)`} : {...el, text: el.name}
        })
        this.setSelectOptions('organization', { options: this.formattedOrganization }, this.fieldsPudo)
      }
    },
    setPudo () {
      if (Object.entries(this.pudo).length > 0) {
        this.setFormatterPudo(this.pudo)
        this.setDinamicField(this.pudo.owner)
        this.changeCountry('country', this.pudo.country, true, true)
      }
    },
    setFormatterPudo (data) {
      this.formPudo = {
        ...data, 
        address: {
          ...data.address, 
          text: data.address.full_address, 
          location: !!data.address.location && !!data.address.location?.latitude && !!data.address.location?.longitude ? data.address.location : null
        }, 
        information: data.address.information,
        emails: data.users.length > 0 ? data.users.map(item => item.email) : []
      }
      const filterCountry = this.countriesNames.filter(item => item.id === data.country.id)
      this.formPudo.country.text = filterCountry[0].name
      if ([this.TYPE.SELLER, this.TYPE.ORGANIZATION].includes(data.owner.type)) {
        this.formPudo.organization = !!data.owner.organization ? {...data.owner.organization, text: data.owner.organization.name} : {...data.owner, text: data.owner.name}
        this.formPudo.shipper = !!data.owner?.id && !!data.owner?.organization?.id ? {...data.owner, text: data.owner.name} : null
      }  
      if (data.owner.type === this.TYPE.COURIER) this.formPudo.courier = {id: data.owner.code, text: data.owner.name, code: data.owner.code}
      this.showMap = !!this.formPudo.address.location ? {latitude: this.formPudo.address.location.latitude, longitude: this.formPudo.address.location.longitude, title: this.formPudo.address.full_address, draggable: true} : null
      this.formatCountryLevels(data)
    },
    formatCountryLevels (data) {
      const country_code = data.country.code.toLowerCase()
      const country = this.internalLevels[country_code].display

      if (country) {
        const last = country[country.length - 1]

        if (Object.keys(data.address.place).length > 0) {
          const level1Data = data.address.place.level1
          const level2Data = data.address.place.level2
          
          this.formPudo[`level${last}_pudo`] = level1Data ? { ...level1Data, text: level1Data.name } : null

          if (['co', 'pe'].includes(country_code)) {
            this.formPudo[`level${country[0]}_pudo`] = level2Data ? { ...level2Data, text: level2Data.name } : null
          }

          if (['mx'].includes(country_code)) { 
            this.formPudo.level3_pudo = level1Data ? {name: level1Data.zip_code, id: level1Data.zip_code, text: level1Data.zip_code} : null
            this.formPudo.state_mx = data.address.place.level4?.name
            this.formPudo.municipality_mx = data.address.place.level3?.name
            this.complete_levels_in_mx = Object.keys(data.address.place).length === 4
          }
        }
      }
    },
    setDinamicField (owner) {
      const index = this.getFieldIndex(this.fieldsPudo, 'code')
      if (owner.type ===  this.TYPE.COURIER) {
        this.fieldsPudo[this.getFieldIndex(this.fieldsPudo, 'emails')].containerClass = 'hide'
        this.fieldsPudo[this.getFieldIndex(this.fieldsPudo, 'emails')].validation = ''
        this.$store.dispatch('fetchService', { name: 'getCarriersPudos'})
        this.fieldsPudo.splice(index + 1, 0, 
          {fieldType: 'FieldSelect', label: 'Courier', useLabel: true, name: 'courier', options: this.carriers, containerClass: 'col-md-4 container-info mb-5', placeholder: 'Seleccione Courier', validation: 'required', change: this.changeCourier, disabled: false}
        )
        // Si es tipo Courier
        this.styleFieldsDisabled()
      }
      if ([this.TYPE.SELLER, this.TYPE.ORGANIZATION].includes(owner.type)) {
        // Si es tipo Organización y usuario Seller
        if ([this.TYPE.ORGANIZATION].includes(owner.type) && this.isSeller) this.styleFieldsDisabled()
        this.fieldsPudo[index].containerClass = 'col-md-5 container-info mb-5 personalize-form-enabled'
        // Condición para crear los campos Empresa/Seller segun corresponda
        if (this.isAdmin || (owner.type === this.TYPE.ORGANIZATION && this.isSeller) || (owner.type === this.TYPE.SELLER && this.isMarketplace)) {
          this.fieldsPudo.splice(index + 1, 0, 
            {fieldType: 'FieldSelect', label: 'Empresa', useLabel: true, name: 'organization', options: this.formattedOrganization, containerClass: !this.isAdmin ? 'col-md-4 container-info mb-5 personalize-form-enabled' : 'col-md-4 container-info mb-5', validation: 'required', change: this.changeOrganization, disabled: !this.isAdmin}, 
            {fieldType: 'FieldSelect', label: 'Seller', useLabel: true, name: 'shipper', options: this.shippersByOrganization, containerClass: 'hide', useSkeleton: false, disabled: false, validation: this.isMarketplace ? 'required' : ''}
          )
          // No mostramos el campo Seller si se ingresa con un usuario Seller
          const indexShipper = this.getFieldIndex(this.fieldsPudo, 'shipper')
          this.fieldsPudo[indexShipper].containerClass = !this.isSeller ? 'col-md-4 container-info mb-5' : 'hide'
          // Tampoco llamamos a la función si es un seller, ya que esta trae los shippers de una organization
          if (!this.isSeller) this.changeOrganization('organization', this.formPudo.organization, true)  
        }     
      }
    },
    // Función para cambiar los campos a disabled y asi el usuario solo pueda visualizar pero no hacer cambios en el detalle
    styleFieldsDisabled () {
      if (!this.isAdmin) {
        this.disabledAllFields = true
        this.$emit('disabledSchedules', this.disabledAllFields)
        if (this.showMap) this.showMap.draggable = false
        this.disabledFields(this.fieldsPudo, ['deadline_days', 'emails'])
        this.disabledFields(this.fieldsPudoCountry, ['address', 'information'])
      }
    },
    disabledFields (fields, names) {
      fields.map(item => {
        if (item.clearable) item.clearable = false
        item.containerClass = 'container-info personalize-form-enabled '
        item.containerClass += names.includes(item.name) ? 'col-md-12' : 'col-md-4'
      })
    },
    getFieldIndex(field, value) {
      return field.findIndex(el => el.name === value)
    },
    validateForm () {
      this.$refs.formRenderPudo.checkForm()
      this.$refs.formRenderPudoCountry.checkForm()
    },
    handleSubmitPudo () {
      if (!!this.formPudo.address && (Object.prototype.hasOwnProperty.call(this.formPudo.address, 'formatted_address') || Object.prototype.hasOwnProperty.call(this.formPudo.address, 'full_address'))) {
        this.btn_saved = true
        this.lastLevelOfPlace = [1, 4].includes(this.formPudo.country?.id) && this.formPudo['level3_pudo'] ? this.formPudo['level3_pudo'] : [3].includes(this.formPudo.country?.id) && this.formPudo.level2_pudo ? this.formPudo.level2_pudo : [2].includes(this.formPudo.country?.id) && this.formPudo.level4_pudo ? this.formPudo.level4_pudo : null 
        const formatterAddress = {
          full_address: this.formPudo.address?.full_address || this.formPudo.address?.formatted_address,
          information: this.formPudo.information || null
        }
        if (this.formPudo.address.location) {
          formatterAddress.location = {
            latitude: this.formPudo.address.location.latitude ? this.formPudo.address.location.latitude.toString() : this.formPudo.address.location.lat.toString(),
            longitude: this.formPudo.address.location.longitude ? this.formPudo.address.location.longitude.toString() : this.formPudo.address.location.lng.toString()
          }
        }
        const formattedType = this.formPudo.organization?.id && !this.formPudo.shipper ? this.TYPE.ORGANIZATION : !!this.formPudo.organization?.id && !!this.formPudo.shipper?.id ? this.TYPE.SELLER : this.TYPE.COURIER
        const payload = {
          activated: this.formPudo.activated,
          code: this.formPudo.code,
          name: this.formPudo.name,
          pudo_type: formattedType,
          organization_id: this.formPudo.organization?.id ? this.formPudo.organization?.id : null,
          shipper_id: this.formPudo.shipper?.id ? this.formPudo.shipper?.id : null,
          carrier_code: this.formPudo.courier?.id ? this.formPudo.courier?.code : null,
          country_code: this.formPudo.country.code,
          place_id: this.lastLevelOfPlace.id,
          platform_place_id: this.lastLevelOfPlace.platform_id,
          deadline_days: Number(this.formPudo.deadline_days) || null,
          users: this.formPudo?.emails.length > 0 ? this.formPudo.emails : null,
          address: formatterAddress
        }     
        const params = {
          id: this.$route.params.id
        }
        const queryParams = {...payload}
        this.$store.dispatch('fetchService', { name: 'putPudo', params, queryParams, onSuccess: (resp) => this.onSuccessPutPudo(resp.data), onError: () => { this.btn_saved = false }})
      } else {
        this.$alert('Dirección inválida')
      }
    },
    onSuccessPutPudo (data) {
      this.setFormatterPudo(data)
      this.$emit('refreshPudo', data)
      this.btn_saved = false
      this.$success(this.$t('msg-exito-actualizar-pudo'))
    },
    setSelectOptions (name, { options }, field) {
      const index = field.findIndex(el => el.name === name)
      if (index !== -1) {
        field[index].options = options
        this.keyFormRenderPudoCountry++
        this.keyFormRenderPudo++
      }
    },
    changeOrganization (name, value, noClean) {
      const index = this.getFieldIndex(this.fieldsPudo, 'shipper')
      if (!!value?.id) {
        const indexOrg = this.getFieldIndex(this.fieldsPudo, 'organization')
        if (!noClean) {
          this.formPudo.shipper = null
          this.fieldsPudo[indexOrg].disabled = true
        }
        this.fieldsPudo[index].containerClass = value.organization_type.id === 2 ? 'hide' : 'col-md-4 container-info mb-5'
        this.fieldsPudo[index].options = []
        const queryParams = {}
        const params = { organization_id: this.formPudo.organization?.id || 0 }
        this.fieldsPudo[index].useSkeleton = true
        if (value.organization_type.id === 1) {
          this.$store.dispatch('fetchService', { name: 'getShippersByOrganizationSimplifiedQueryParams', queryParams, params, onSuccess: () => this.onSuccessShipper(index, indexOrg, value, noClean), onError: () => this.onErrorShipper(index, indexOrg)})
        } else {
          this.formPudo.shipper = {id: value.id, text: value.name}
          if (!noClean) this.getOrganization(value, indexOrg)
        }
      } else {
        this.fieldsPudo[index].containerClass = 'hide'
        this.formPudo.shipper = null
        this.formPudo.organization = null
        this.keyFormRenderPudo++
        this.changeCountry('country', null)
        return null
      }        
    },
    onSuccessShipper (index, indexOrg, value, noClean) {
      this.fieldsPudo[index].useSkeleton = false 
      if (!noClean) this.getOrganization(value, indexOrg)
    },
    onErrorShipper (index, indexOrg) {
      this.fieldsPudo[indexOrg].disabled = false
      this.fieldsPudo[index].useSkeleton = false
    },
    changeCountry (name, value, notForceClean, changeForce) {
      const indexAddress = this.getFieldIndex(this.fieldsPudoCountry, 'address')
      this.fieldsPudoCountry[indexAddress].dependency = value?.id ? `level${Math.max(...this.internalLevels[value.code.toLowerCase()].required)}_pudo` : 'country'
      if (!notForceClean) {
        this.formPudo = {
          ...this.formPudo,
          address: undefined,
          information: undefined,
          level1_pudo: undefined,
          level2_pudo: undefined,
          level3_pudo: undefined,
          level4_pudo: undefined,
          state_mx: '',
          municipality_mx: ''
        }
      }
      this.fieldsPudoCountry = this.fieldsPudoCountry.filter(el => !el.name.includes('level') && !el.name.includes('skeleton'))
      this.country = value
      this.formPudo = {
        ...this.formPudo,
        country: value
      }
      this.setLevelsParentMX()
      //limpia todo
      if (!value?.id) return null
      //Guardo los campos normales
      let indexFromCountry = this.fieldsPudoCountry.findIndex(el => el.name === name)

      indexFromCountry++
      const display = this.internalLevels[this.country.code.toLowerCase()].display

      display.map(el => {
        this.fieldsPudoCountry.splice(indexFromCountry, 0, { name: `skeleton${el}`, useSkeleton: true, containerClass: 'col-md-4 container-info' })
      })

      this.fieldsPudoCountry.splice(indexFromCountry, 0)
      //Pego los campos originales
      if (value?.id) {
        this.nextLevelData = {}
        if (['mx'].includes(value.code.toLowerCase())) {
          this.nextLevelData.level = 1
        }
        this.getLevels(value, false, changeForce)
      }
      
    },
    getLevels (country, forceGetLevels, changeForce) {
      const params = {country: country.code.toLowerCase() }
      const { queryParams, onSuccessFx } = this.getQueryParamLevel(country.code.toLowerCase(), changeForce)
      if (!['mx'].includes(params.country) || forceGetLevels) {
        this.$store.dispatch('fetchService', { name: 'getLevels', params, queryParams, onSuccess: (data1, data2) => onSuccessFx(data1, data2)})
      } else {
        this.$store.commit('setLevels', [])
        this.loadedLevels('getLevels', changeForce)
      }
    },
    getQueryParamLevel (country_code, changeForce) {
      let queryParams = {}
      let onSuccessFx = () => this.loadedLevels('getQueryParamLevel', changeForce)
      if (['co', 'mx', 'pe'].includes(country_code)) {
        queryParams = {...this.nextLevelData}
        delete queryParams.next
        if (this.nextLevelData.next) {
          onSuccessFx = (data) => {
            this.setManualOptions(data.data.level1, this.nextLevelData.next)
          }
        }
      }
      return { queryParams, onSuccessFx }
    },
    searchZipCode (name, value) {
      const countryID = this.formPudo.country.id
      const params = {
        country_id: countryID
      }
      const queryParams = {
        str: value
      }
      this.$store.dispatch('fetchService', { name: 'getZipcode', queryParams, params, onSuccess: (resp) =>  {
        const response = resp.data.zip_codes.map(val => ({'name': val, 'id': val})) //El array se convierte en objeto
        response.map((row) => {
          //se convierte en string porue da error con los otros types del payload al agregarse como option
          Object.keys(row).forEach((key) => {
            return row[key] = `${row[key]}`
          })
          row.text = row.name
        })
        this.$refs.formRenderPudoCountry.setManualOptions(name, response)
      }, hideAlert: true}) 
    },
    loadedLevels (origin, changeForce) {
      const full_places = this.$generateArrayPlacesFromTree(this.levels)
      this.country = this.isAdmin ? this.formPudo.country : this.sessionUser.country 
      this.fieldsPudoCountry = this.fieldsPudoCountry.filter(el => el.name.indexOf('skeleton') === -1).filter(el => !el.name.includes('level'))
      const indexSplice = this.getFieldIndex(this.fieldsPudoCountry, 'country') + 1
      const required = !!this.isAdmin ? this.internalLevels[this.country.code.toLowerCase()].required : [0]
      const country = {
        code: this.country.code.toLowerCase(),
        requiredLevels: required,
        displayLevels: this.internalLevels[this.country.code.toLowerCase()].display,
        maxLevel: Math.max(...this.internalLevels[this.country.code.toLowerCase()].required),
        levels: full_places,
        loadLevelsOnSelection: false,
        stepByStep: ['co', 'mx', 'pe'].includes(this.country.code.toLowerCase()),
        level3Search: ['mx'].includes(this.country.code.toLowerCase()) ? this.searchZipCode : null
      }
      const fieldOrigin = {
        fieldType: 'FieldsLevels',
        name: 'levels',
        extraName: 'pudo',
        country,
        dependency: 'country',
        change: this.changePlaceLevel,
        containerClass: this.disabledAllFields ? 'col-md-4 container-info personalize-form-enabled' : 'col-md-4 container-info'
      }
      this.fieldsPudoCountry.splice(indexSplice, 0, fieldOrigin)
      const indexCourier = this.getFieldIndex(this.fieldsPudo, 'courier')
      if (indexCourier !== -1 && this.isAdmin) this.fieldsPudo[indexCourier].disabled = false
      if (!!changeForce) {
        const country = this.internalLevels[this.country.code.toLowerCase()].display
        const last = country[country.length - 1] 
        if (['co', 'pe'].includes(this.country.code.toLowerCase())) {
          this.changePlaceLevel(`level${last}_pudo`, null, 'undefined_pudo', true)
        }
        // Se pasa la variable complete_levels_in_mx para validar que venga todos los niveles de Mexico para simular las acciones del usuario 
        // Como si realizara el change en cada selector y con esto tener la lista de opciones en cada uno
        if (['mx'].includes(this.country.code.toLowerCase()) && this.complete_levels_in_mx) {
          this.changePlaceLevel('level3_pudo', this.formPudo.level3_pudo, 'level4_pudo', true, true)
        }
      }
    },
    changePlaceLevel (name, value, next, notForceClean, notSetForm) {
      const level = name
      if (!notSetForm) this.formPudo = {...this.formPudo, [next]: undefined}
      if (!notForceClean) {
        this.formPudo = {
          ...this.formPudo,
          address: undefined,
          information: undefined,
          state_mx: next === 'null_pudo' ? '' : this.formPudo.state_mx,
          municipality_mx: next === 'null_pudo' ? '' : this.formPudo.municipality_mx
        }
      }
      if (['co', 'mx', 'pe'].includes(this.formPudo.country.code.toLowerCase())) {
        const currentLevel = +name.split('_')[0].replace('level', '')
        this.nextLevelData = {
          level: this.internalLevels[this.formPudo.country.code.toLowerCase()].required.length === currentLevel ? currentLevel : currentLevel + 1,
          parent_id: this.internalLevels[this.formPudo.country.code.toLowerCase()].required.length === currentLevel ? this.formPudo[level.replace(`level${currentLevel}`, `level${currentLevel > 1 ? currentLevel - 1 : currentLevel}`)].id : value.id,
          next: this.internalLevels[this.formPudo.country.code.toLowerCase()].required.length === currentLevel ? name : next
        }
        if (['level3_pudo', 'level4_pudo'].includes(name) && ['mx'].includes(this.formPudo.country.code.toLowerCase())) {
          this.nextLevelData.zip_code = this.nextLevelData.parent_id
          delete this.nextLevelData.parent_id
          delete this.nextLevelData.level
        }
      }
      this.getLevels(this.formPudo.country, true)   
    },
    setManualOptions (places, fieldTo) {
      if (places) {
        const full_places = this.$generateArrayPlacesFromTree(places)
        this.setLevelsParentMX(full_places)
        const level_num = +fieldTo.split('_')[0].replace('level', '')
        this.$refs.formRenderPudoCountry.setManualOptions(fieldTo, full_places.filter(place => place.level_num === level_num))
      }
    },
    setLevelsParentMX (places) {
      const stateField  = {origin: 'fieldsPudoCountry', name: 'state_mx', level: 1}
      const municipalityField  = {origin: 'fieldsPudoCountry', name: 'municipality_mx', level: 2}
      if (['mx'].includes(this.formPudo.country.code.toLowerCase())) {
        const stateText = this.getPlaceTextByLevel(places, stateField.level) || this.formPudo.state_mx
        const municipalityText = this.getPlaceTextByLevel(places, municipalityField.level) || this.formPudo.municipality_mx
        this.updateFormField(stateField, stateText, 'col-md-4 container-info')
        this.updateFormField(municipalityField, municipalityText, 'col-md-4 container-info')
      } else {
        this.updateFormField(stateField, '', 'hide')
        this.updateFormField(municipalityField, '', 'hide')
      }
    },
    getPlaceTextByLevel(places, level) {
      if (places) {
        const place = places.find(p => p.level_num === level)
        return place ? place.text : ''
      }
    },
    updateFormField(field, text, containerClass) {
      const selectedField = this[field.origin][this.getFieldIndex(this[field.origin], field.name)]
      if (selectedField) {
        selectedField.containerClass = containerClass
        this.formPudo[selectedField.name] = text || ''
      }
    },
    getFullAddress (value) {
      let text = `${value}, `
      if (this.formPudo.country?.id) {
        this.internalLevels[this.formPudo.country.code.toLowerCase()].required.map(level => {
          if (this.formPudo[`level${level}_pudo`] && this.formPudo[`level${level}_pudo`].id) {
            text += `${this.formPudo[`level${level}_pudo`].text},`
          }
          text = text.substring(0, text.length - 1)
        })
      }
      return text
    },
    searchGoogleAddress (value) {
      value = this.getFullAddress(value)
      return this.baseService.callService('getGoogleAddress', {address: value, country_id: this.formPudo.country.id})
        .then(resp => {
          const validateAddress = resp.data.filter((el, index) => {
            const indexStreetName = el.address_components.findIndex(el => el.types.includes('route'))
            const indexStreetNumber = el.address_components.findIndex(el => el.types.includes('street_number'))
            return indexStreetName !== -1 && indexStreetNumber !== -1
          })
          const listAddress = validateAddress.length > 0 ? validateAddress : resp.data
          const resultAddress = listAddress.map(address => {
            return {
              id: address.place_id,
              formatted_address: address.formatted_address,
              text: address.formatted_address,
              location: address.geometry.location
            }
          })
          resultAddress.suggestions_address_validate_google = validateAddress.length === 0
          return resultAddress
        
        })
    },
    changePositionMarkerMap (name, value) {
      if (value?.id) {
        this.showMap = {latitude: value.location.lat, longitude: value.location.lng, title: value.formatted_address, draggable: true}
      }
    },
    changeDirection (new_direction) {
      this.formPudo.address = new_direction
    },
    changeCourier (name, value) {
      if (value?.id) {
        const filterCountry = this.countries.filter(item => item.id === value.country_id)
        if (this.formPudo.country?.id !== filterCountry[0].id) {
          const indexCourier = this.getFieldIndex(this.fieldsPudo, 'courier')
          this.fieldsPudo[indexCourier].disabled = true
          this.formPudo.country = filterCountry[0]
          this.changeCountry('country', this.formPudo.country)
        }
      } else {
        this.formPudo.country = null
        this.changeCountry('country', this.formPudo.country)
      }     
    },
    getOrganization (value, index) {
      if (!value?.id) return
      this.baseService.callService('getOrganization', null, { id: value.id })
        .then(resp => {
          this.fieldsPudo[index].disabled = false
          const resultCountry = resp.data.country
          if (this.formPudo.country?.id !== resultCountry.id) {
            this.formPudo.country = resultCountry
            this.changeCountry('country', this.formPudo.country) 
          }
        })
        .catch(err => {
          this.fieldsPudo[index].disabled = false
        })
    }
  }
}
</script>

<style>
.personalize-status {
    font-size: 16px;
}
.container-info-general {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 2rem;
}
.personalize-form-enabled input, .personalize-form-enabled .vs__dropdown-toggle, .personalize-form-enabled .input-group-text {
  background: none;
  border: none;
  color: #AEAEAE;
  background-color: white !important;
}
.personalize-form-enabled .vs__selected {
  color: #AEAEAE !important;
}
 .customize-info {
    margin-left: 1rem;
    opacity: 0.5;
    color: #5C5C5C;
    font-size: 15px;
    border: none;
    margin-bottom: 0 !important;
}
#map {
  border-radius: 22px;
  overflow: hidden;  
}
.margin-top-btn-create {
    margin-top: 15rem;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  margin: 0 2rem;
}
.flex-start {
  position: absolute;
  bottom: 0;
  margin: 0 4rem;
}
.hide {
  display: none;
}
#emails .b-form-tags.disabled {
  background: none !important;
}
.switch-pudo 
.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:active ~ .custom-control-label::before {
    border-color: #1E8E3E;
    background-color: #1E8E3E;
}
@media (max-width: 768px) {
  .container-info-general {
    display: block;
    position: relative;
  }
  .personalize-btn-saved-changes {
    position: initial;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}
}
</style>